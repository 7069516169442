<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">401</h1>
          <h4 class="pt-3">Oops! You're not Authorized to access the app.</h4>
          <p class="text-muted">Please contact your Web-admin</p>
        </div>
        <CButton color="info"
            to="/login"
            >Go Back To Login</CButton>

      </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Page401'
}
</script>
